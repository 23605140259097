import { WebLocale } from '@/enums/web-locale';
import { TitleDetail } from '@/interfaces/title-details-graphql';

type PromotionProviders = 9 | 337;

const promotionProviderIcons: Record<PromotionProviders, string> = {
	9: `${JW_CONFIG.IMAGESCALER_FAST_URL}/icon/52449539/s100/amazonprime.webp`,
	// 119: `${JW_CONFIG.IMAGESCALER_FAST_URL}/icon/52449539/s100/amazonprime.webp`,
	337: `${JW_CONFIG.IMAGESCALER_FAST_URL}/icon/313118777/s100/disneyplus.webp`,
};

interface PromoText {
	/** Set `null` to disable. */
	ribbon: string | null;
	/** Set `null` to disable. */
	offerText: string | null;
	/** Set `null` to disable. */
	longText: string | null;
	/** Optional promotion start date in `YYYY-MM-DD` format. */
	startDate?: ISO8601;
	/** `YYYY-MM-DD` format. */
	endDate: ISO8601;
	/** The ID of the promoted provider. */
	provider: PromotionProviders;
	/** The Url of the promoted provider which overrides the regular offer link. */
	url?: string;
}

/** Normal PromoText config with the Provider Icon URL */
type PromoTextWithIcon = PromoText & { icon: string };

type PromotionCountries = 'AU' | 'HK' | 'NZ' | 'SG' | 'TW' | 'US';

const promotions: Record<PromotionCountries, PromoText[]> = {
	AU: [
		{
			ribbon: 'OFFER',
			offerText: 'Limited time offer',
			longText: 'Limited time Disney+ offer',
			startDate: '2024-09-20',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
	],
	HK: [
		{
			ribbon: 'OFFER',
			offerText: '快閃限時優惠',
			longText: '每日唔使 HK$2，精彩娛樂一整年！',
			startDate: '2024-09-12',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
	],
	NZ: [
		{
			ribbon: 'OFFER',
			offerText: 'Limited time offer',
			longText: 'Limited time Disney+ offer',
			startDate: '2024-09-20',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
	],
	SG: [
		{
			ribbon: 'OFFER',
			offerText: 'Save 25%*',
			longText: 'Less than S$10/month*',
			startDate: '2024-09-12',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
	],
	TW: [
		{
			ribbon: 'OFFER',
			offerText: '快閃限時優惠',
			longText: '每天不到 NT$5，精彩娛樂一整年！',
			startDate: '2024-09-12',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
	],
	US: [
		{
			ribbon: 'OFFER',
			offerText: '1.99/Month',
			longText: 'Get 3 Months For $1.99/Mo',
			startDate: '2024-09-18',
			endDate: '2024-09-28',
			provider: 337,
			url: 'http://disneyplus.bn5x.net/G5y0r',
		},
		{
			ribbon: 'OFFER',
			offerText: 'FREE TRIAL',
			longText: '30-DAY FREE TRIAL',
			startDate: '2024-09-20',
			endDate: '2024-10-31',
			provider: 9,
		},
	],
};

export function getPromoText(
	country: string,
	offers: TitleDetail['offers'] = [],
	currentDate: Date = new Date()
): PromoTextWithIcon | null {
	const texts = promotions[country as PromotionCountries];
	if (!texts || texts.length === 0 || offers.length === 0) return null;

	const promoText = texts.find(promo => offers.some(offer => offer.package.packageId === promo.provider));

	if (promoText == null) return null;
	// Checking if we're within the promo dates
	if (promoText.startDate && +currentDate < new Date(promoText.startDate).setUTCHours(0, 0, 0, 0)) return null;
	if (promoText.endDate && +currentDate > new Date(promoText.endDate).setUTCHours(23, 59, 59, 999)) return null;

	return { ...promoText, icon: promotionProviderIcons[promoText.provider] };
}

// Disney External Pixel Tracking
const oceaniaLocales = new Set([WebLocale.au, WebLocale.nz]);
const latamLocales = new Set([
	WebLocale.br,
	WebLocale.mx,
	WebLocale.ar,
	WebLocale.cl,
	WebLocale.co,
	WebLocale.pe,
	WebLocale.uy,
	WebLocale.ec,
	WebLocale.pa,
	WebLocale.cr,
	WebLocale.py,
	WebLocale.bo,
	WebLocale.gt,
	WebLocale.ni,
	WebLocale.do,
	WebLocale.sv,
	WebLocale.hn,
	WebLocale.ve,
]);

export function getDisneyImpressionUrl(packageId: number | null | undefined, webLocale: WebLocale) {
	if (packageId === 337 /* Disney+ */) {
		if (oceaniaLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1833790/9358';

		if (latamLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1848147/9358';
	}

	if (packageId === 619 /* Star+ */) {
		if (latamLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1584316/13591';
	}

	return null;
}

// TYPES
type digits = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type day = `${'0'}${Exclude<digits, '0'>}` | `${'1' | '2'}${digits}` | '30' | '31';
type month = `${'0'}${Exclude<digits, '0'>}` | '10' | '11' | '12';
type year = `20${digits}${digits}`;
type ISO8601 = `${year}-${month}-${day}`;
